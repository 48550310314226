import React, { useState, useEffect, useRef, memo } from 'react'
import Menu from './appbar';
import ErrorDialog from './errordialog';
import { useNavigate } from "react-router-dom";
import { Grid, Link, Card, CardContent, Typography, Box, Collapse, CardHeader, Rating, CardMedia, CardActions, IconButton, Button, Paper, FormControlLabel, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../helper/services';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode'
import { useTranslation } from 'react-i18next';
import { styled, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand, marginleft }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: marginleft,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const TypographyRef = styled(Typography)(({ theme }) => ({
    verticalAlign: 'middle',
    display: 'inline-flex',
    cursor: 'pointer'

}));

const FormControlLabelItem = styled(FormControlLabel)(({ theme }) => ({
    cursor: 'default'
}));
const CheckboxlItem = styled(Checkbox)(({ theme }) => ({
    cursor: 'default'
}));


const Smdresult = () => {
    const age = useRef();
    const dob = useRef();
    const gender = useRef();
    const navigate = useNavigate();
    const [htmlresult, setHtmlresult] = useState();
    const [result, setResult] = useState({});
    const [urgentcolor] = useState(["", "text.primary", "warning.main", "error.main"]);
    let { id, view } = useParams();
    const [qr, setQr] = useState('');
    const [error, seterror] = useState('');
    const { t } = useTranslation('translations');
    const [expanded, setExpanded] = useState(view ? true : false);
    const [openRef, setOpenRef] = React.useState(false);
    let othercheck = {};


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const GenerateQRCode = (url) => {
        QRCode.toDataURL(url, {
            width: 250,
            margin: 2,
            color: {
                // dark: '#335383FF',
                // light: '#EEEEEEFF'
            }
        }, (err, url) => {
            if (err) return console.error(err)
            //console.log(url)
            setQr(url)
        })
    }
    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    async function getResult(id) {

        var result = await api.getResult(id);
        if (result) {
            age.current = result.age;
            dob.current = result.items.find(e => e.name == "dob")?.value;
            gender.current = result.gender;
            GenerateQRCode(document.URL.replace("result", "view"));

            //console.log(result.items);
            result.items = groupBy(result.items, "topic_id");
            setResult(result);
            setHtmlresult(result.logic_result);

        }

    };

    useEffect(() => {
        if (id !== "undefined")
            getResult(id);
        else {
            seterror("invalid id");
        }
    }, [])

    function mapitem(e) {

        if (e.item_type === "radio") {
            var item_property = e.item_property ? JSON.parse(e.item_property) : {};
            if (e.value !== item_property.value) return;
            else othercheck = { name: e.name, value: e.value };

        }
        if (e.item_type === "otherradio" || e.item_type === "othercheckbox") {
            if (!e.value) return;
            if (othercheck.name == e.name) return;


        }

        if (e.name === "topiclist") {
            e.item_type = "radio";
            e.item_property = `{"value":"${e.value}"}`;
            e.item_note = e.topic_note;
        }

        return (
            <React.Fragment key={(e.page_name || 'item' + e.page_id) + (e.isother ? "other" : "") + e.id} >
                {(() => {
                    switch (e.item_type) {

                        case "radio":
                        case "checkbox":
                            return (<div><label htmlFor={(e.page_name || 'item' + e.page_id) + e.id}><input
                                type={e.item_type}
                                readOnly
                                name={e.name || e.page_id}
                                {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                id={(e.page_name || 'item' + e.page_id) + e.id}
                                checked={e.value == "1" || (e.item_type == 'radio')}
                                value={e.value} />
                                {t(e.item_note)}</label></div>)

                        case "date":
                            return (<div><DesktopDatePicker
                                label={t(e.note)}
                                inputFormat="DD/MM/YYYY"
                                value={e.value}
                                onChange={(newValue) => { }}
                                renderInput={(params) => <TextField size="small" {...params}
                                    name={e.name || e.page_id}
                                    {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                    id={(e.page_name || 'item' + e.page_id) + e.id}
                                />}
                            /></div>)

                        case "hidden":
                        case null:
                            if (e.item_type == "hidden" || e.name.includes("checked")) {
                                return (<input
                                    type={e.item_type || "hidden"}
                                    name={e.name || e.page_id}
                                    {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                    id={(e.page_name || 'item' + e.page_id) + e.id}
                                    value={e.value}
                                />)
                            }
                        default:
                            return (<div><TextField
                                size="small"
                                readOnly
                                multiline
                                type={e.item_type == "number" ? "text" : e.item_type}
                                label={t(e.item_note)}
                                name={e.name || e.page_id}
                                {...(e.item_property ? JSON.parse(e.item_property) : {})}
                                id={(e.page_name || 'item' + e.page_id) + e.id}
                                value={e.value}
                            /></div>)
                        // code block
                    }
                })()}


            </React.Fragment>)
    }

    function mappage(page_item) {
        if (page_item.length === 0) return;
        if (!view) {
            if (page_item[0].topic_id === 0 && page_item[0].name !== 'topiclist') {
                return;
            }
        } else {
            if (page_item[0].topic_id === 0 && page_item[0].name == 'dob') {
                let ageitem = page_item.find(e => e.name == "age")
                ageitem.item_type = "number"
            }
        }
        // console.log(page_item);
        return <Card sx={{ minWidth: 275, m: 1 }} key={page_item[0].page_id} >
            <CardContent ><Typography mb={1}>{t(page_item[0].note)}</Typography>
                <Stack spacing={2} alignItems="stretch">
                    {page_item.map(mapitem)}
                </Stack>
            </CardContent>
        </Card>
    }
    function maptopic(page_by_topic) {

        var page = groupBy(page_by_topic, "page_id");
        var item = Object.values(page).sort(function (a, b) { return Math.min(...a.map(o => o.id)) - Math.min(...b.map(o => o.id)) });
        var topic = item.map(mappage);

        return topic;

    }


    function generateTree(obj) {
        var main = groupBy(obj, "level");
        var items = Object.keys(main).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })

        return <>{items.map((el, j) => <div key={j}> {el !== "undefined" && <Typography mt={1} variant="h6" className={"levelheader" + el}  >{t("suggest level " + el)}</Typography>}
            <>{main[el].map((object, i) => <Stack key={"level" + el + "child" + i}>
                {object.code && <Typography mb={1} ml={1} variant="subtitle1" color="text.primary">{t(object.code)}</Typography>}
                {object.comment && <Typography ml={(object.code ? 2 : 1)} mt={(object.code ? -1 : 1)} mb={(object.code ? 1 : 1)} variant={(object.code ? "body2" : "subtitle1")} color={(object.code ? "text.secondary" : "text.primary")}>{t(object.comment)}</Typography>}
            </Stack>)}
            </></div>
        )}</>

    }
    function PackageItem(props) {
        const { row } = props;
        const [openItem, setOpenItem] = React.useState(false);


        return <><Grid container onClick={() => setOpenItem(!openItem)}
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            mr={1}
        >
            <Grid item xs={1}>
                <ExpandMore
                    expand={openItem}
                    aria-expanded={openItem}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Grid>
            <Grid item xs={8}>
                <Typography ml={1} variant="body1" color="primary">{t(row.title_EN)}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography ml={1} vsx={{ fontSize: 14 }} color="secondary">{t('match')}</Typography>

                    <Rating name="size-small" defaultValue={row.recommed * 0.05} precision={0.1} size="small" readOnly />
                </Stack>
            </Grid>
        </Grid>
            <Collapse in={openItem} timeout="auto" unmountOnExit >
                {row.package_items.sort(function (a, b) { return (b.level ?? 0) - (a.level ?? 0) }).map((object, i) =>
                    <Stack ml={1} key={"packageitem" + i}>
                        <FormControlLabelItem control={<CheckboxlItem
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon className={"levelheader" + object.level} />}
                            onChange={() => object.hit == 1} checked={object.hit == 1} />}
                            label={t(object.item_description)} />
                    </Stack>
                )}

            </Collapse>
        </>
    }

    function generatepackageTree(obj) {
        var main = groupBy(obj, "level");
        var items = Object.keys(main).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })

        return <>{items.map((el, j) => <Stack key={j} spacing={1} > {false && <Typography mt={1} variant="h6" className={"levelheader" + el}  >{t("suggest level " + el)}</Typography>}
            <>{main[el].sort(function (a, b) { return b.recommed - a.recommed }).map((object, i) =>
                <Paper style={{ display: 'inline-block', ml: 1 }} elevation={2} key={"level" + el + "child" + i}>{object.package_items && <PackageItem row={object} />}</Paper>)}
            </></Stack>
        )}</>

    }

    function generateTreeV2(obj) {
        var main = groupBy(obj, "level");
        var items = Object.keys(main).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })

        return <>{items.map((el, j) => <div key={j}>
            <>{main[el].map((object, i) => <Stack key={"level" + el + "child" + i}>
                {(object.code || object.comment) && <FormControlLabelItem control={<CheckboxlItem
                    icon={<BookmarkBorderIcon />}
                    checkedIcon={<BookmarkIcon className={"otherlevelheader" + object.level} />}
                    onChange={() => true} checked={true} />}
                    label={t(object.code ? object.code : object.comment)} />}
                {object.comment && object.code && <Typography ml={(object.code ? 4 : 1)} mt={(object.code ? -1 : 1)} mb={(object.code ? 1 : 1)} variant={(object.code ? "body2" : "subtitle1")} color={(object.code ? "text.secondary" : "text.primary")}>{t(object.comment)}</Typography>}
            </Stack>)}
            </></div>
        )}</>

    }

    function sendcallback(method) {
        var jsonData = {
            "type": "finish_smd",
            "ticket_id": id,
            //"chat_room_id":result.chat_room_id,
            "url": document.URL.replace("result", "view"),
            "contact_topic": method

        };
        if (method == "emergencycall") jsonData["call_no"] = "1745";
        if (method == "packageinfo") jsonData["call_no"] = "0657302546";

        QRCode.toDataURL(jsonData.url, {
            width: 250,
            margin: 2,
            color: {
                // dark: '#335383FF',
                // light: '#EEEEEEFF'
            }
        }, (err, url) => {
            if (err) return console.error(err)
            //console.log(url)
            jsonData.qr_code_base64 = url;
            console.log(jsonData);
            window?.ReactNativeWebView?.postMessage(JSON.stringify(jsonData));
        })

    }

    return (
        <Grid sx={{ justifyContent: 'center' }}>
            <Menu self_exam={(result?.self_exam === "Y")} dob={dob.current} age={age.current} gender={gender.current} allowPrevious={view} PreviousCallback={() => { view && navigate("/search/") }} />
            <Box m={2} sx={{ justifyContent: 'center' }}
            >
                <ErrorDialog message={error} />
                <Card>
                    <CardHeader
                        title={<span dangerouslySetInnerHTML={
                            { __html: (t(view ? "Result" : "Your Result") + ' (' + t((result.self_exam === "Y" ? "selfanswer" + (age.current < 10 && view ? "under10" : "") : "not selfanswer"), { interpolation: { escapeValue: false } }) + ')') }}></span>}

                        subheader={t("resultdatetime", {
                            val: new Date(result.update_date), formatParams: {
                                val: {
                                    day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit',
                                    minute: '2-digit',
                                },
                            }
                        })}
                    />
                    
                    <CardContent sx={{ p: 1, bgcolor: "rgba(0, 0, 0, 0.12)" }}>
                        {htmlresult?.urgency && <Paper elevation={2}><Grid m={1} p={1}><Typography variant="h5" component="div" color={urgentcolor[htmlresult?.urgency]} >{t("urgencylevel" + htmlresult?.urgency)}</Typography></Grid></Paper>}
                        {<Paper elevation={2}><Grid m={1} p={1}><Typography color="text.secondary" variant="subtitle" fontSize="small" dangerouslySetInnerHTML={
                            { __html: t('suggest other detail' +( htmlresult?.package?.length > 0?" package":""), { interpolation: { escapeValue: false } }) }}  ></Typography></Grid></Paper>}
                        {htmlresult?.ddx?.length > 0 && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5" >{t("suggest diag")}</Typography>
                            {
                                generateTreeV2(htmlresult?.ddx)
                            }
                        </Grid></Paper>}
                        {htmlresult?.package?.length > 0 && <Paper elevation={2} ><Grid m={1} p={1}><Typography component={'span'} mt={1} variant="h5">{t("suggest package")} {!view && <TypographyRef color="primary" variant="body2" fontSize="small" onClick={() => sendcallback("packageinfo")} >{t('package-price')}<Link ml={1} onClick={() => sendcallback("packageinfo")} target="_blank"><OpenInNewIcon fontSize="small" /></Link></TypographyRef>}</Typography>

                            {
                                generatepackageTree(htmlresult?.package)
                            }
                        </Grid></Paper>}
                        {htmlresult?.inv?.length > 0 && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5"  >{t("suggest inv")}</Typography>
                            {
                                generateTreeV2(htmlresult?.inv)
                            }
                        </Grid></Paper>}
                        {htmlresult?.vac?.length > 0 && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5">{t("suggest vac")}</Typography>
                            {
                                generateTreeV2(htmlresult?.vac)
                            }
                        </Grid></Paper>}
                        {htmlresult?.other?.length > 0 && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5">{t("suggest other")}</Typography>
                            {
                                generateTreeV2(htmlresult?.other)
                            }
                        </Grid></Paper>}
                        {htmlresult?.comment && typeof htmlresult?.comment == "string" && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5">{t(htmlresult?.comment)}</Typography>
                        </Grid></Paper>}
                        {htmlresult?.note && view && typeof htmlresult?.note == "string" && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5">{t(htmlresult?.note)}</Typography>
                        </Grid></Paper>}
                        {htmlresult?.reference?.length > 0 && <Paper elevation={2}><Grid m={1} p={1}><Typography mt={1} variant="h5" onClick={() => setOpenRef(!openRef)} >{t("Reference")}<ExpandMore
                            expand={openRef}
                            aria-expanded={openRef}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore></Typography>

                            <Collapse in={openRef} timeout="auto" unmountOnExit >
                                <Stack>
                                {htmlresult?.reference.map(function (object, i) {
                                    return <TypographyRef key={i} m={1} variant="body1" color="text.secondary">{t(object.label)}<Link ml={1} href={object.url} target="_blank"><OpenInNewIcon fontSize="small" /></Link></TypographyRef>;
                                })}
                            </Stack></Collapse></Grid></Paper>}
                    </CardContent>
                    {!view &&
                        <Stack><Typography variant="subtitle" component="div" >{t("showqr")}</Typography><CardMedia
                            component="img"
                            height="250"
                            sx={{ objectFit: "contain" }}

                            image={qr}
                            alt={t("Page QR code")}
                        /></Stack>
                    }
                    {!view && <CardActions disableSpacing  >
                        <Stack direction="row" m={0} spacing={1}>
                            {htmlresult?.urgency == 3 && <Button variant="contained" color="error" onClick={() => sendcallback("emergencycall")}>{t("Emergency call")}</Button>}
                            <Button variant="contained" color="primary" onClick={() => sendcallback("appointment")}>{t("Appointment")}</Button>
                            <Button variant="contained" color="primary" onClick={() => sendcallback("telemed")}>{t("Tele-med")}</Button>
                        </Stack>

                    </CardActions>}
                    <CardActions disableSpacing onClick={handleExpandClick}>

                        <Typography variant="body2" color="text.secondary"  >
                            {t(view ? "Answer" : "Your Answer")}
                        </Typography>
                        <ExpandMore
                            marginleft='auto'
                            expand={expanded}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit  >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <CardContent sx={{ bgcolor: 'text.disabled' }} >

                                {result.items
                                    ? (
                                        //topic.items.map(e => <Trans i18nKey={e.item_description} key={e.id} >{ReactHtmlParser(createMarkup(e))}</Trans>)
                                        //topic.items.map(e => <div key={e.id} dangerouslySetInnerHTML={createMarkup(e)}></div>)
                                        Object.values(result.items).sort(function (a, b) { return Math.min(...a.map(o => o.id)) - Math.min(...b.map(o => o.id)) }).map(maptopic)


                                    ) : ''
                                }




                            </CardContent>
                        </LocalizationProvider>
                    </Collapse>
                </Card>

            </Box>
        </Grid>)

};

export default Smdresult;