import React, { useState, useEffect, useRef, memo } from 'react'
import { useNavigate } from "react-router-dom";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { Stack, Grid, styled, Checkbox, Radio, FormControlLabel, Button, TextField, Box, Collapse, Alert, IconButton, Typography } from '@mui/material';
import { t } from 'i18next';
import api from '../helper/services';
import Menu from './appbar';
import moment from 'moment';

const Smdsearch = () => {
    const [result, setResult] = useState([]);
    const [urgentcolor] = useState(["", "text.primary", "text.primary", "error.main"]);
    const navigate = useNavigate();
    async function search_result(data) {
        var result = await api.searchResult(data);
        for (const item of result) {
            try {
                item.logic_result = JSON.parse(item.logic_result);
                item.urgency = item.logic_result.urgency;
            } catch (ex) { }
        }

        setResult(result);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const data = {};
        for (const pair of (new FormData(e.target)).entries()) {
            if (pair[1] && pair[1].length >= 3 || pair[0]=="isurgency")
                data[pair[0]] = pair[1];
        }
        if (Object.keys(data).length > 0) {
            search_result(data);
        }
    }

    useEffect(() => {
        search_result({
            dateFromSearch: moment().add(-1, 'd').format("YYYY-MM-DD"),
            dateToSearch: moment().add(1, 'd').format("YYYY-MM-DD")
        })

    }, []);

    return <div> <Menu /> <Stack

        m={2}
        spacing={2}

    ><Grid container onSubmit={(e) => handleSubmit(e)}
        noValidate
        autoComplete="off"
        justifyContent="space-between"
        alignItems="baseline"
        component="form">
            <Grid item >
                <TextField
                    id="id-search"
                    name="idSearch"
                    helperText={t("Enter ID")}
                    label={t("ID")}
                    size='small'
                    type="text"

                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item >
                <TextField
                    id="tel-search"
                    name="telSearch"
                    helperText={t("Enter Telphone number")}
                    label={t("Telephone Number")}
                    size='small'
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item>
                <TextField
                    id="email-search"
                    name="emailSearch"
                    helperText={t("Enter Email")}
                    label={t("Email")}
                    size='small'
                    type="text"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item >
                <TextField
                    id="dateFrom-search"
                    name="dateFromSearch"
                    helperText={t("Enter Date")}
                    label={t("From")}
                    size='small'
                    type="date"
                    defaultValue={moment().add(-1, 'd').format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item >
                <TextField
                    id="dateTo-search"
                    name="dateToSearch"
                    helperText={t("Enter Date")}
                    label={t("To")}
                    size='small'
                    type="date"
                    defaultValue={moment().add(1, 'd').format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item >
            <label htmlFor="isurgency" ><input
                                type="checkbox"
                                name="isurgency"
                                value ="1"
                                id="isurgency"
                            />
                                <Typography component="span" color={urgentcolor[3]} >{t("urgencylevel3")}</Typography></label>
            </Grid>
            <Grid item >
                <Button variant="contained" sx={{
                    width: '25ch',
                }} type="submit" color='secondary'>{t("Search")}</Button>
            </Grid>

        </Grid>
        <div style={{ height: 'calc(100vh - 160px)', width: '100%' }}>
            <DataGrid
                rows={result}

                columns={[
                    { field: 'transaction_id', headerName: t('ID'), width: 250 },
                    { field: 'topic_note', headerName: t('Topic'), width: 350, valueFormatter: params => t(params?.value) },
                    { field: 'urgency', headerName: t('urgencylevel'), width: 250, renderCell: params => <Typography component="div" color={urgentcolor[params?.value]} >{params.value?t("urgencylevel" + params.value):''}</Typography>} ,
                    { field: 'self_exam', headerName: t('Self Exam'),width: 200, valueFormatter: params => (params?.value == "Y" ? t("Yes") : t("No")) },
                    { field: 'age', headerName: t('Age') },
                    { field: 'gender', headerName: t('Gender'), valueFormatter: params => (params?.value == "M" ? t("Male") : t("Female")) },
                    {
                        field: 'update_date', headerName: t('Result Date'), type: 'dateTime', width: 250,
                        valueFormatter: params =>
                            t("{{val, datetime}}", {
                                val: new Date(params?.value), formatParams: {
                                    val: { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', },
                                }
                            })
                    },

                    {
                        field: 'result_id',
                        headerName: t('Open Result'),
                        sortable: false,
                        filterable: false,
                        width: 120,
                        renderCell: (params) => (
                            <Button
                                variant="outlined"
                                size="small"
                                color='secondary'
                                style={{ marginLeft: 16 }}
                                tabIndex={params.hasFocus ? 0 : -1}
                                onClick={() => { navigate("/view/" + params?.value) }}
                            >
                                {t('Open')}
                            </Button>
                        ),
                    }

                ]}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableColumnSelector

            />
        </div>
    </Stack></div>
}

export default Smdsearch;