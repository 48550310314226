

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#0ab4ab',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f50057',
    },
  },
};